import Fieldset from './fieldset'

import FieldsetTitle from './fieldset-title'
import FieldsetSubtitle from './fieldset-subtitle'
import FieldsetFooter from './fieldset-footer'
import FieldsetContent from './fieldset-content'

Fieldset.Footer = FieldsetFooter
Fieldset.Content = FieldsetContent
Fieldset.Subtitle = FieldsetSubtitle
Fieldset.Title = FieldsetTitle

export default Fieldset
