import React from 'react'

interface FieldsetFooterActionsProps extends React.HTMLAttributes<any> {
  className?: string
}

const FieldsetFooterActions: React.FC<FieldsetFooterActionsProps> = ({ className = '', children, ...props }) => {
  return (
    <>
      <div className={`fieldset-footer-actions ${className}`} {...props}>
        {children}
      </div>
      <style jsx>{`
        .fieldset-footer-actions {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  )
}

const MemoFieldsetFooterActions = React.memo(FieldsetFooterActions)

export default MemoFieldsetFooterActions
