import { HTMLAttributes } from 'react'

import FieldsetFooterStatus from './fieldset-footer-status'
import FieldsetFooterActions from './fieldset-footer-actions'

export interface FieldsetFooterProps extends HTMLAttributes<HTMLDivElement> {}

const FieldsetFooter: React.FC<React.PropsWithChildren<FieldsetFooterProps>> = ({ className = '', children, ...props }) => {
  return (
    <footer className={`fieldset-footer ${className}`} {...props}>
      {children}
      <style jsx>{`
        .fieldset-footer {
          @apply bg-accent-1 border-t border-accent-2 border-solid rounded-b-md;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          color: #444;
          padding: 8pt 16pt;
          font-size: 0.875rem;
          min-height: 2.875rem;
          box-sizing: border-box;
        }
      `}</style>
    </footer>
  )
}

type FieldsetFooterComponent<P = Record<string, unknown>> = React.FC<P> & {
  Status: typeof FieldsetFooterStatus
  Actions: typeof FieldsetFooterActions
}

export default FieldsetFooter as FieldsetFooterComponent<FieldsetFooterProps>
