import { HTMLAttributes } from 'react'

export interface FieldsetContentProps extends HTMLAttributes<HTMLDivElement> {}

const FieldsetContent: React.FC<React.PropsWithChildren<FieldsetContentProps>> = ({ className = '', children, ...props }) => {
  return (
    <div className={`fieldset-content ${className}`} {...props}>
      {children}
      <style jsx>{`
        .fieldset-content {
          padding: 16pt;
        }
        .fieldset-content :global(> :first-child) {
          margin-top: 0;
        }
        .fieldset-content :global(> :last-child) {
          margin-bottom: 0;
        }
      `}</style>
    </div>
  )
}

export default FieldsetContent
