import React, { HTMLAttributes } from 'react'

export interface FieldsetSubtitleProps extends HTMLAttributes<HTMLDivElement> {}

const FieldsetSubtitle: React.FC<FieldsetSubtitleProps> = ({ className = '', children, ...props }) => {
  return (
    <>
      <div className={`fieldset-subtitle ${className}`} {...props}>
        {children}
      </div>
      <style jsx>{`
        .fieldset-subtitle {
          font-size: 0.875rem;
          line-height: 1.6;
          letter-spacing: -0.005625rem;
          margin: 8pt 0px;
        }
      `}</style>
    </>
  )
}

const MemoFieldsetTitle = React.memo(FieldsetSubtitle)

export default MemoFieldsetTitle
