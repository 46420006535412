import React, { HTMLAttributes } from 'react'

export interface FieldsetTitleProps extends HTMLAttributes<HTMLDivElement> {}

const FieldsetTitle: React.FC<FieldsetTitleProps> = ({ className = '', children, ...props }) => {
  return (
    <>
      <div className={`fieldset-title ${className}`} {...props}>
        {children}
      </div>
      <style jsx>{`
        .fieldset-title {
          font-size: 1.25rem;
          line-height: 1.5;
          margin: 0;
          display: inline-flex;
          word-break: break-word;
          font-weight: 600;
          letter-spacing: -0.020625rem;
        }
      `}</style>
    </>
  )
}

const MemoFieldsetTitle = React.memo(FieldsetTitle)

export default MemoFieldsetTitle
