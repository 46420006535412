import FieldsetTitle from './fieldset-title'
import FieldsetSubtitle from './fieldset-subtitle'
import FieldsetFooter from './fieldset-footer'
import FieldsetContent from './fieldset-content'
import React, { ReactNode, useMemo } from 'react'
import { hasChild, pickChild } from '@eq/utils/collections'

interface Props {
  value?: string
  label?: string
  title?: string | ReactNode
  subtitle?: string | ReactNode
}

type NativeAttrs = Omit<React.FieldsetHTMLAttributes<any>, keyof Props>
export type FieldsetProps = Props & NativeAttrs

const Fieldset: React.FC<React.PropsWithChildren<FieldsetProps>> = ({ className = '', title = '', subtitle = '', children, ...props }) => {
  const [withoutFooterChildren, FooterChildren] = pickChild(children, FieldsetFooter)
  const hasTitle = hasChild(withoutFooterChildren, FieldsetTitle)
  const hasSubtitle = hasChild(withoutFooterChildren, FieldsetSubtitle)
  const hasContent = hasChild(withoutFooterChildren, FieldsetContent)

  const content = useMemo(
    () => (
      <>
        {!hasTitle && title && <FieldsetTitle>{title}</FieldsetTitle>}
        {!hasSubtitle && subtitle && <FieldsetSubtitle>{subtitle}</FieldsetSubtitle>}
        {withoutFooterChildren}
      </>
    ),
    [withoutFooterChildren, hasTitle, hasSubtitle, title, subtitle]
  )

  return (
    <div className={`fieldset ${className}`} {...props}>
      {hasContent ? content : <FieldsetContent>{content}</FieldsetContent>}
      {FooterChildren && FooterChildren}
      <style jsx>{`
        .fieldset {
          @apply bg-white border rounded-md;
          overflow: hidden;
          display: block;
        }
      `}</style>
    </div>
  )
}

type FieldsetComponent<P = Record<string, unknown>> = React.FC<P> & {
  Title: typeof FieldsetTitle
  Subtitle: typeof FieldsetSubtitle
  Footer: typeof FieldsetFooter
  Content: typeof FieldsetContent
  Body: typeof FieldsetContent
}

export default Fieldset as FieldsetComponent<FieldsetProps>
