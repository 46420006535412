import React from 'react'

interface FieldsetFooterStatusProps extends React.HTMLAttributes<any> {
  className?: string
}

const FieldsetFooterStatus: React.FC<FieldsetFooterStatusProps> = ({ className = '', children, ...props }) => {
  return (
    <>
      <div className={`fieldset-footer-status ${className}`} {...props}>
        {children}
      </div>
      <style jsx>{`
        .fieldset-footer-status {
          font-size: 0.875rem;
          line-height: 1.2;
          margin: 0;
          display: inline-flex;
          word-break: break-word;
        }
        .fieldset-footer-status > :global(p) {
          margin: 0;
        }
      `}</style>
    </>
  )
}

const MemoFieldsetFooterStatus = React.memo(FieldsetFooterStatus)

export default MemoFieldsetFooterStatus
